import React from 'react'
import { useState, useEffect } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../css/master.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { api, bannerurl } from '../Costants'
import ReactLoading from 'react-loading';
function Banner_sec() {
  const [users, setUsers] = useState([])
  const details_fetch = async () => {
    const response = await fetch(api + 'home_slider_list', {
      method: "POST",
      mode: 'cors',
      headers: {
        'Accept': 'aplication/json',
        "Content-Type": "application/json"
      }
    });
    const data = await response.json();

    setUsers(data.records)

  }
  useEffect(() => {
    details_fetch();
  }, [])
  return (
    <div>
      <div className="article-row-1">
        <Carousel fade prevIcon={<FontAwesomeIcon icon={faArrowLeft} className="fawsome_cls" />} nextIcon={<FontAwesomeIcon icon={faArrowRight} className="fawsome_cls" />}>
          {users !== undefined && users.length > 0 ? users.map((Items) => (
            <Carousel.Item >
              <img className="d-block w-100 bannner" src={bannerurl + Items.banner_image} alt={Items.banner_image} />
              <Carousel.Caption>
                <h5 >{Items.banner_title}</h5>
                <p>{Items.banner_description}</p>
                <a href='/contact'>Enquire Now</a>
              </Carousel.Caption>
            </Carousel.Item>
          )) : null
          }
        </Carousel>
      </div>
    </div>
  )
}
export default Banner_sec;