import { NavLink, Link } from 'react-router-dom';
import '../css/master.css';

import Call from '../Components/images/icons/call.png'

import Logo from '../Components/images/logo.png';
import Mail from '../Components/images/icons/mail.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Double1 from '../Components/images/double-right-arrow.png'
import { AiOutlineMenu } from 'react-icons/ai';

import {
  faAngleDown,
  faGreaterThan,
  faStar,
  faStarHalfAlt,
  faAngleUp,
} from '@fortawesome/free-solid-svg-icons';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownToggle from 'react-bootstrap/DropdownToggle';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import DropdownItem from 'react-bootstrap/DropdownItem';
import React, { useState, useEffect } from 'react';
import ScrollToTop from 'react-scroll-to-top';
import { api } from './Costants';
import Image1 from './../Components/images/logo.png';
import { useParams } from 'react-router-dom';
import { FaFacebookF, FaInstagram, FaPinterest } from 'react-icons/fa';
import { AiFillYoutube } from 'react-icons/ai';
import { GrLinkedinOption } from 'react-icons/gr';
function Headerss() {
  const [show, setShow] = useState(false)
  const params = useParams()

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const container = document.querySelector('.navbar-collapse');
    if (container) {
      container.style.display = isVisible ? 'block' : 'none';
    }
  }, [isVisible]);

  const toggleVisibility = () => {
    setIsVisible(prevState => !prevState);
  };


  const showDropdown = (e) => {
    setShow(!show)
  }
  const hideDropdown = (e) => {
    setShow(false)
  }


  const [stickyClass, setStickyClass] = useState('')

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar)
    return () => window.removeEventListener('scroll', stickNavbar)
  }, [])

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY
      windowHeight > 130 ? setStickyClass('sticky-nav') : setStickyClass('')
    }
  }






  
  const [menuOpen, toggleMenuOpen] = useState(false)

  const [menuOpenone, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpenone);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const [menuOpenonesub, setMenuOpensub] = useState(false);

  const toggleMenusub = () => {
    setMenuOpensub(!menuOpenonesub);
  };

  const closeMenusub = () => {
    setMenuOpensub(false);
  };



  const [users, setUsers] = useState([])
  const details_fetch = async () => {
    const response = await fetch(api + 'services_list  ', {
      method: "POST",
      mode: 'cors',
      headers: {
        'Accept': 'aplication/json',
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ meta_slug: params.meta_slug })
    });
    const data = await response.json();

    setUsers(data.records)
  }

  useEffect(() => {
    details_fetch();

  }, [params])

  const [userst, setUserst] = useState([])
  const details_fetcht = async () => {
    const response = await fetch(api + 'company_profile_list', {
      method: "POST",
      mode: 'cors',
      headers: {
        'Accept': 'aplication/json',
        "Content-Type": "application/json"
      }
    });
    const data = await response.json();

    setUserst(data.records[0])
  }




  useEffect(() => {
    details_fetcht();

  }, [])



  const [users5, setUsers5] = useState([]);
  const [user6, setUsers6] = useState([]);
  const params1 = useParams();
  if (params1.sub_metaslug != undefined && params1.sub_metaslug !== "") {
    var url_name = params1.sub_metaslug;
    var api_url = api + "sub_service_details";
  } else {
    var url_name = params1.meta_slug;
    var api_url = api + "service_details";
  }
  const details_fetch2 = async () => {
    const response = await fetch(api_url, {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "aplication/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ meta_slug: url_name }),
    });
    const data = await response.json();
    setUsers5(data.records);
  };
  useEffect(() => {
    details_fetch2();
    details_fetch3();
  }, [params]);
  //sub service list
  const details_fetch3 = async () => {
    const response = await fetch(api + "service_categories", {
      method: "POST",
      mode: "cors",
      headers: {
        Accept: "aplication/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ meta_slug: params.meta_slug }),
    });
    const data = await response.json();
    setUsers6(data.records);
  };
  useEffect(() => {
    details_fetch2();
    details_fetch3();
  }, [params1]);

  return (
    <div>
      <ScrollToTop
        smooth
        component={<FontAwesomeIcon icon={faAngleUp}></FontAwesomeIcon>}
        className="scroll icon" />

      <header className="header">
        <div className="header-row-1 pt-10 pb-10 p-2">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-5 col-md-7">
                <div className="row">
                  <div className="col-md-12 header-1-inner hr1-left">
                    <div className="header-1-icon ">
                      <img src={Mail}></img>
                    </div>
                    <div className="header-1-content">
                      <h5>Email Us</h5>
                      <p>
                        <a href={`mailto:${userst.email}`}>
                          <span className="number">
                            {' '}
                            {userst.email}
                          </span>
                        </a>
                      </p>
                    </div>
                  </div>

                </div>



              </div>
              <div className="col-lg-2 d-md-none d-lg-block">
                <div className="header-logo hr1-center d-none d-md-none d-sm-none d-xl-block d-lg-block web-logo">
                  <Link to="/">
                    <img src={Logo} width="100%"></img>
                  </Link>
                </div>
              </div>

              <div className="col-lg-2 col-md-5 header-1-inner call-us">
                <div className="header-1-icon ">
                  <img src={Call}></img>
                </div>
                <div className="header-1-content">
                  <h5>Call Us</h5>
                  <p>
                    <a href={`tel:+91${userst.mobile}`}>
                      <span className="number">+91 {userst.mobile}</span>{' '}
                    </a>
                  </p>
                </div>
              </div>
              <div className='col-lg-3 header-social-link'>
                <div className="footer-social-link">
                  <ul className='d-flex justify-content-around mb-0'>
                    <li> <a href={userst.facebook}><FaFacebookF /></a> </li>
                    <li> <a href={userst.instagram}><FaInstagram /></a> </li>
                    <li> <a href={userst.linked_in}><GrLinkedinOption /></a> </li>
                    <li> <a href={userst.youtube}><AiFillYoutube /></a> </li>
                    <li> <a href={userst.twitter}> <FaPinterest /></a> </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-2 col-md-12">
                <div className="header-logo hr1-center d-block d-md-block d-sm-block d-xl-none d-lg-none mobile-logo">
                  <Link to="/">
                    <img src={Logo} alt="" width="100%" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mobile-header pt-10 pb-10">
          <div className="container">
            <div className="row">
              <div className="col-sm-8 col-md-6 col-lg-4 mobile-text-left">
                <a href={`mailto:${userst.email}`}>
                  <i className="fas fa-envelope"></i>{' '}
                  {userst.email}
                </a>
              </div>
              <div className="col-sm-4 col-md-6 col-lg-4 mobile-text-right">
                <a href={`tel:+91${userst.mobile}`}>
                  <i className="fas fa-phone-alt"></i> +91 {userst.mobile}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="header-row-2">
          <div className="container head">
            <Navbar expand="lg" className={`navbar ${stickyClass}`}>
              <Container>

                
                <div className='mobile-view'>
                  <div className="mobile-view-main">
                    <Navbar.Brand ><Link to='/'><img src={Image1} className='img-fluid imgelogo'></img></Link></Navbar.Brand>
                    <div className="position-relative"><button onClick={toggleVisibility}>

                    
                      {isVisible ? <AiOutlineMenu /> : <AiOutlineMenu />}
                    </button> </div>
                  </div>

                  {isVisible && (<div> <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="m-auto mt-lg-0">
                      <li className="nav-item home">
                        <NavLink onClick={toggleVisibility} className="nav-link" to="/">
                          <span className="hom">Home</span>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink onClick={toggleVisibility} className="nav-link" to="/about">
                          <span className="hom">About Us</span>
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink onClick={toggleVisibility} className="nav-link" to="/theme">
                          <span className="hom">Themes</span>
                        </NavLink>
                      </li>

                      <li className='dropdown_main_sec'>
                      <div className="dropdown-container">

                        <div className="dropdown-toggle">
                          <span className="title text-white">Services</span>
                          <FontAwesomeIcon onClick={toggleMenu} className={`dropdown-icon ${menuOpen ? 'open' : ''}`} icon={faAngleDown}></FontAwesomeIcon>
                        </div>

                        {menuOpenone && (
                          <div className="dropdown-menu_one" onMouseLeave={closeMenu}>
                            {user6 != undefined && user6.length > 0
                              ? user6.map((Items) => {
                                return (




                                  <ul><li>
                                    <div className="dropdown-container_sub">
                                      <div className="dropdown-toggle_sub" onClick={toggleMenusub}></div>
                                      <Link onClick={toggleVisibility}

                                        to={`/servicedetail/${Items.meta_slug}`}>

                                        <span className='text'> {Items.services_title} </span></Link>
                                    
                                     {Items.sub_service ? <FontAwesomeIcon onClick={toggleMenusub} className={`dropdown-icon_one ${menuOpen ? 'open' : ''}`} icon={faAngleDown}></FontAwesomeIcon> : ''} 
                                      
                                    {menuOpenonesub && (
                                      <div>
                                      <div className="dropdown-menu_sub" onMouseLeave={closeMenusub}>
                                           {Items.sub_service != undefined &&
                                        Object.keys(Items.sub_service).length > 0
                                        ? Items.sub_service.map((sub) => {
                                          return (


                                          <ul><li>
                                              <Link onClick={toggleVisibility} to={`/servicedetail/${Items.meta_slug}/${sub.meta_slug}`}>
                                                <img src={Double1} alt="arrow"></img>
                                                <span className='textone pl-1'>{sub.services_title}</span>
                                              </Link>

                                              </li></ul>



                                          );
                                        })
                                        : null}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                  </li>
                                  </ul>


                        );
                              })
                              : null}
                      </div>
                        )}
                    </div>

                    </li>

                   <li className="nav-item">
                      <NavLink onClick={toggleVisibility} className="nav-link" to="/gallery">
                        <span className="hom">Gallery</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink onClick={toggleVisibility} className="nav-link" to="/contact">
                        <span className="hom">Contact us</span>
                      </NavLink>
                    </li>
                    <li className="nav-item  enquire-button">
                      <a href="/contact">Enquire Now</a>
                    </li>
                  </Nav>


                  </Navbar.Collapse></div>)}
              </div>


              <div className='webview'>
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="m-auto mt-lg-0">
                    <li className="nav-item home">
                      <NavLink className="nav-link" to="/">
                        <span className="hom">Home</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/about">
                        <span className="hom">About Us</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/theme">
                        <span className="hom">Themes</span>
                      </NavLink>
                    </li>



                    <Dropdown className='nav-toggle-btn servise_btn  my-dropdown-toggle  nav-dropdown'
                      onMouseEnter={() => {
                        toggleMenuOpen(true)
                      }}
                      onMouseLeave={() => {
                        toggleMenuOpen(false)
                      }}
                      show={menuOpen}
                    >



                      <Dropdown.Toggle className='nav-item'>
                        <NavLink className="nav-link" to="/service">
                          <span className="hom">Services</span>{' '}
                          <FontAwesomeIcon className='dropdown-icon' icon={faAngleDown}></FontAwesomeIcon>
                        </NavLink>
                      </Dropdown.Toggle>



                      <Dropdown.Menu className="dropdown_menu dropdown_menu--animated dropdown_menu-7 dropdown_menu_scroll">

                        {user6 != undefined && user6.length > 0
                          ? user6.map((Items) => {
                            return (

                              <div className='dropdown_inner_one'>

                                {/* <Dropdown.Item className="dropdown_inner dropdown-item title-menu"> */}

                                <Link to={`/servicedetail/${Items.meta_slug}`} className="dropdown_inner dropdown-item title-menu">

                                  <span className='text'> {Items.services_title}</span>
                                  <div className='dropdown_inner_two'>
                                    {Items.sub_service != undefined &&
                                      Object.keys(Items.sub_service).length > 0
                                      ? Items.sub_service.map((sub) => {
                                        return (


                                          <Dropdown.Item className="dropdown_inner sub-title-menu">

                                            <Link onClick={toggleVisibility} to={`/servicedetail/${Items.meta_slug}/${sub.meta_slug}`}>

                                              <span className='textone'>{sub.services_title}</span>
                                            </Link>

                                          </Dropdown.Item>


                                        );
                                      })
                                      : null} </div>

                                </Link>
                                {/* </Dropdown.Item> */}

                              </div>

                            );
                          })
                          : null}

                      </Dropdown.Menu>
                    </Dropdown>

                    <li className="nav-item">
                      <NavLink className="nav-link" to="/gallery">
                        <span className="hom">Gallery</span>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/contact">
                        <span className="hom">Contact us</span>
                      </NavLink>
                    </li>
                    <li className="nav-item  enquire-button">
                      <a href="/contact">Enquire Now</a>
                    </li>
                  </Nav>
                </Navbar.Collapse>
              </div>
            </Container>
          </Navbar>
        </div>
    </div>
      </header >
    </div >
  )
}

export default Headerss
